import React, { Suspense } from "react";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Loader from "../components/organisms/Loader";

const Login = React.lazy(() => import("../components/pages/login/login"));
const Welcome = React.lazy(() => import("../components/pages/welcome/welcome"));
const Editor = React.lazy(() => import("../components/pages/editor/editor"));
const Guidelines = React.lazy(() => import("../components/pages/Guidelines"));


const AppRouter = ({ isAuthenticated }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <PrivateRoute
            path="/guidelines/:roomId"
            component={Guidelines}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/editor/:roomId"
            component={Editor}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path={`/login/:roomId`}
            component={Login}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            exact
            path="/"
            component={Welcome}
            isAuthenticated={isAuthenticated}
          />
          <Redirect from="*" to={"/"} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default AppRouter;
