import { SET_ICE_SERVERS } from "../actions/IceConfig";

export const initialState = {
  iceServers: null
};

const stunServerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ICE_SERVERS:
      return { iceServers: action?.payload };

    default:
      return state;
  }
};

export default stunServerReducer;
