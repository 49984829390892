import React from 'react';
import './Loader.scss';

const Loader = ({cls=""}) => {
  return (
      <div className={`page-loading ${cls ? cls : ''}`}><div className="dot-pulse" /></div>
  );
};

export default Loader;
