import { Box } from '@material-ui/core';
import './App.scss';
import AppRouter from "./routes";
import ClientProvider from "./services/ClientProvider";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import configureStore from "./store";
import { ToastContainer, toast } from 'react-toastify';
const { persistor, store } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Box className="App">
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              borderRadius: '6px', 
              padding: '5px', 
              fontSize: '15px', 
              width: '350px'
            }}
          />
          <ClientProvider>
            <AppRouter />
          </ClientProvider>
        </Box>
      </PersistGate>
    </Provider>
  );
}

export default App;
