import React from "react";


const ClientProvider = ({ children }) => {

  const isAuthenticated = true;

  return (
      <>
        {
          React.cloneElement(children, { isAuthenticated })
        }
      </>
  );
};

export default ClientProvider;
