export const SET_USER = 'SET_USER';
export const SET_USER_NAME = 'SET_USER_NAME';
export const SET_USER_COLOR = 'SET_USER_COLOR';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_MEETING_ID = 'SET_USER_MEETING_ID';

export const setUser = (user) => ({
  type: SET_USER,
  payload: {
    ...user
  }
});

export const setUserName = (name) => ({
  type: SET_USER_NAME,
  payload: name
});

export const setUserColor = (color) => ({
  type: SET_USER_COLOR,
  payload : color
});

export const setUserType = (type) => ({
  type: SET_USER_TYPE,
  payload: type
});


export const setUserMeetingId = (meetingId) => ({
  type: SET_USER_MEETING_ID,
  payload: meetingId
});

